import { Link } from "wouter";
import { useTranslation } from "react-i18next";
import ThemeToggle from "./ThemeToggle";

// We're removing the TranslationLoader component and handling namespaces directly
export default function Footer() {
  // Load multiple namespaces directly in the component
  const { t } = useTranslation(['footer', 'booking']);

  return (
    <footer className="bg-muted">
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h3 className="font-bold text-lg mb-4">WeKlean QC</h3>
            <p className="text-sm text-muted-foreground">
              {t('footer:description')}
            </p>
            <ul className="space-y-2 text-sm mt-4">
              <li>
                <Link href="/impact">{t('footer:communityImpact')}</Link>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="font-semibold mb-4">{t('booking:frequency')}</h4>
            <ul className="space-y-2 text-sm">
              <li>
                <Link href="/about">{t('footer:aboutUs')}</Link>
              </li>
              <li>
                <Link href="/how-it-works">{t('footer:howItWorks')}</Link>
              </li>
              <li>
                <Link href="/cleaners">{t('footer:bookNow')}</Link>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="font-semibold mb-4">{t('footer:support')}</h4>
            <ul className="space-y-2 text-sm">
              <li>
                <Link href="/faq">{t('footer:faq')}</Link>
              </li>
              <li>
                <Link href="/gallery">{t('footer:gallery')}</Link>
              </li>
              <li>
                <Link href="/contact">{t('footer:contactUs')}</Link>
              </li>
              <li>
                <Link href="/blog">{t('footer:blog')}</Link>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="font-semibold mb-4">{t('footer:legal')}</h4>
            <ul className="space-y-2 text-sm">
              <li>
                <Link href="/privacy">{t('footer:privacyPolicy')}</Link>
              </li>
              <li>
                <Link href="/terms">{t('footer:termsOfService')}</Link>
              </li>
              <li>
                <Link href="/referral-program">{t('common:referralProgramTitle')}</Link>
              </li>
            </ul>
          </div>
          <ThemeToggle />
        </div>

        <div className="mt-8 pt-8 border-t text-center text-sm text-muted-foreground">
          <p>{t('footer:copyright', { year: new Date().getFullYear() })} - {t('footer:currentVersion')}</p>
        </div>
      </div>
    </footer>
  );
}