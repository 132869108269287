import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

// Import common translations that should always be available
import commonEN from "../locales/en/common.json";
import commonFR from "../locales/fr/common.json";
import headerEN from "../locales/en/header.json";
import headerFR from "../locales/fr/header.json";
import footerEN from "../locales/en/footer.json";
import footerFR from "../locales/fr/footer.json";
import bookingEN from "../locales/en/booking.json";
import bookingFR from "../locales/fr/booking.json";
import galleryEN from "../locales/en/gallery.json";
import galleryFR from "../locales/fr/gallery.json";
import homeEN from "../locales/en/home.json";
import homeFR from "../locales/fr/home.json";
import newsletterEN from "../locales/en/newsletter.json";
import newsletterFR from "../locales/fr/newsletter.json";
import cleanerEN from "../locales/en/cleaner.json";
import cleanerFR from "../locales/fr/cleaner.json";
import profileEN from "../locales/en/profile.json";
import profileFR from "../locales/fr/profile.json";
import calendarEN from "../locales/en/calendar.json";
import calendarFR from "../locales/fr/calendar.json";
import recommendationsEN from "../locales/en/recommendations.json";
import recommendationsFR from "../locales/fr/recommendations.json";
import reviewsEN from "../locales/en/reviews.json";
import reviewsFR from "../locales/fr/reviews.json";
import authEN from "../locales/en/auth.json";
import authFR from "../locales/fr/auth.json";
import propertiesEN from "../locales/en/properties.json";
import propertiesFR from "../locales/fr/properties.json";
import onboardingEN from "../locales/en/onboarding.json";
import onboardingFR from "../locales/fr/onboarding.json";
import landingEN from "../locales/en/landing.json";
import landingFR from "../locales/fr/landing.json";
// Initialize with only the common namespace preloaded
const resources = {
  en: {
    common: commonEN,
    header: headerEN,
    footer: footerEN,
    booking: bookingEN,
    cleaner: cleanerEN,
    gallery: galleryEN,
    home: homeEN,
    newsletter: newsletterEN,
    profile: profileEN,
    calendar: calendarEN,
    recommendations: recommendationsEN,
    reviews: reviewsEN,
    auth: authEN,
    properties: propertiesEN,
    onboarding: onboardingEN,
    landing: landingEN,
  },
  fr: {
    common: commonFR,
    header: headerFR,
    footer: footerFR,
    booking: bookingFR,
    cleaner: cleanerFR,
    gallery: galleryFR,
    home: homeFR,
    newsletter: newsletterFR,
    profile: profileFR,
    calendar: calendarFR,
    recommendations: recommendationsFR,
    reviews: reviewsFR,
    auth: authFR,
    properties: propertiesFR,
    onboarding: onboardingFR,
    landing: landingFR,
  },
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "fr", // French as default
    supportedLngs: ["fr", "en"],
    debug: process.env.NODE_ENV === "development",
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["localStorage", "navigator"],
      caches: ["localStorage"],
    },
    // Configure namespaces
    ns: [
      "common",
      "header",
      "footer",
      "booking",
      "gallery",
      "home",
      "newsletter",
      "cleaner",
      "profile",
      "calendar",
      "recommendations",
      "reviews",
      "auth",
      "properties",
      "onboarding",
      "landing",
    ],
    defaultNS: "common",
    fallbackNS: "common",
    backend: {
      // Path to load resources from
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    // Enable partitioning of translations
    partialBundledLanguages: true,
  });

export default i18n;
